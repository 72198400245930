<template>
  <div class="GalleryFileDelete">
    <div dpadding tcenter strong>{{ $locale["delete_confirm"] }}</div>
    <div dpadding>
      <Confirm :confirm="deleteConfirm" />
    </div>
  </div>
</template>

<script>
import gallery from "./gallery";
export default {
  mixins: [gallery],
  props: ["onDeleted"],
};
</script>
